<template>
  <v-main>
  <div class="main-content main-content_fw">
    <Card class="sticky-filters card-custom" v-on:keyup.enter="applyFilters">
    <template #content>
        <v-row>
          <v-col class="filters-column">
            <!-- <Calendar :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" :showIcon="true" /> -->
            <!-- <div class="p-inputgroup">
            </div> -->
           <div class="d-flex align-center">
              <Button
              v-if="filters.agent_id && !isAgent"
              @click.stop="toClearFilterAgent"
              icon="pi pi-times" class="p-button-danger"/>
              <paggination-listbox
              v-if="!isAgent"
              v-model="filters.agent_id"
              :options="agents"
              @start-loading="startAgentsLoading"
              :maxWidthMenu="600"
              :loading="loadingAgentsFlag"
              :loadingStart="startAgentsLoadingFlag"
              @load-next-page="loadAgentsNextPage"
              :placeholder="$t('agents')"
              optionValue="id" optionLabel="login"
              ></paggination-listbox>
           </div>
            
            <InputText 
            v-if="isAgent || filters.agent_id"
            disabled
            :value="getCurrencyValue"
            type="text"
             class="p-inputtext-sm" 
             :placeholder="$t('currency')" />
            <Dropdown 
            class="custom-dropdown"
            v-else
            v-model="filters.currency" 
            :value="getCurrencyValue"
            :options="currencies"
            :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
          </Dropdown>
        <Dropdown v-model="filters.result" :options="results"
        :placeholder="$t('format')" optionValue="value" optionLabel="value" :emptyFilterMessage="$t('no_data')">
      </Dropdown>
      <date-range-picker id="datepicker_id" 
      :autoApply="true"
      :showDropdowns="true" ref="picker" :locale-data="locale_data"
                v-model="dateRange" format="yyyy/mm/dd" opens="center"
                :max-date="today.toISOString()">
                <div slot="input" slot-scope="picker">{{ dateRange.startDate | date }} - {{ 
                  dateRange.endDate | date }}
                </div>
              </date-range-picker>
                <span class="p-buttonset">
                  <Button @click="deleteFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
                  <Button @click="generateReport" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('generate_report')" icon="pi pi-file-import" class="p-button-sm p-button-success" />
              </span>
              <div class="field-checkbox d-flex align-center ml-1">
                <Checkbox id="binary" v-model="filters.show_blank_data" :binary="true" />
                <label for="binary" class="ml-1">{{ $t('show_blank_data') }}</label>
              </div>
          </v-col>
          </v-row>
            <!-- <v-chip-group>
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group> -->
    </template>
    </Card>
    <Card class="mt-2 card-custom" v-if="showReport">
      <template #content>
        <div class="report">
          <div class="report__header header-report">
              <h2 class="text-uppercase">
                {{ $t('games_report') }}
              </h2>
              <Divider />
              <h6>
               from {{ getFormattedDates(filters.from_date) }} to {{ getFormattedDates(filters.end_date) }}
              </h6>
              <div class="report__table table-report">
              </div>
              <div class="space-y-1">
                <Card class="card-custom">
                <template #title>
                  <h4 class="text-uppercase text-center">
                    {{ $t('overall') }}
                  </h4>
                  </template>
                  <template #content>
                    <DataTable :value="overallTotals.items" :rows="overallTotals.items.length" :loading="loadingFlag"
               :scrollable="true" responsiveLayout="scroll" class="p-datatable-sm">
              <Column field="sessions_count" 
              :header="$t('sessions_count')"
                      :showFilterMenu="false" 
                     bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="bet" 
              :header="$t('bet')"
                      :showFilterMenu="false" 
                     bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="win" 
              :header="$t('win')"
                      :showFilterMenu="false" 
                     bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="ggr" 
              :header="$t('ggr')"
                      :showFilterMenu="false" 
                      bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="rtp" 
              :header="$t('rtp')"
                      :showFilterMenu="false" 
                      bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="provider_fee_sum" 
              v-if="!isAgent && !filters.agent_id"
              :header="$t('provider_fee_sum')"
                      :showFilterMenu="false" 
                      bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
              <Column field="provider_fee_percent" 
              v-if="!isAgent && !filters.agent_id"
              :header="$t('provider_fee_percent')"
                      :showFilterMenu="false" 
                      bodyClass="font-weight-bold"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
              </Column>
                  </DataTable>
                  </template>
                </Card>
                <Card class="card-custom" v-if="!isAgent && !filters.agent_id">
                <template #title>
                  <h4 class="text-uppercase text-center">
                    {{ $t('aggregators') }}
                  </h4>
                  </template>
                  <template #content>
                    <DataTable :value="aggregatorsTotals" :rows="aggregatorsTotals.length" :loading="loadingFlag"
               :scrollable="true" responsiveLayout="scroll" class="p-datatable-sm">
              <Column field="name" 
                      :showFilterMenu="false" 
                      :header="$t('aggregator')"
                      bodyClass="bg-c-white"
                      headerClass="bg-c-white text-uppercase"
                      :styles="{ 'min-width': '12rem' }"
              >
                <template #body="{ data }">
                  <v-col class="pa-0 d-flex flex-wrap"><span class="font-weight-bold">{{ data.name }}</span></v-col>
                </template>
              </Column>
      <Column v-for="(value, key) in aggregatorsTotals[0]" v-if="key !== 'name'" 
              :key="key" :field="key"
              :header="$t(key)"
              headerClass="justify-end text-uppercase bg-c-white" 
              bodyClass="justify-end bg-c-white" 
              :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <div class="d-flex align-center font-weight-bold" style="white-space: nowrap;">
            {{ data[key] }}
          </div>
        </template>
      </Column>
                  </DataTable>
                  </template>
                  
                  
                </Card>
                <Card class="card-custom" v-if="providersTotals.length">
                <template #title>
                  <h4 class="text-uppercase text-center">
                    {{ $t('providers') }}
                  </h4>
                  </template>
                  <template #content>
                    <DataTable :value="providersTotals" :rows="providersTotals.length" :loading="loadingFlag"
                    :scrollable="true" responsiveLayout="scroll" class="p-datatable-sm">
                   <Column field="name" 
                           :showFilterMenu="false" 
                           :header="$t('provider')"
                           bodyClass="bg-c-white"
                           headerClass="bg-c-white text-uppercase"
                           :styles="{ 'min-width': '12rem' }"
                   >
                     <template #body="{ data }">
                       <v-col class="pa-0 d-flex flex-wrap"><span class="font-weight-bold">{{ data.name }}</span></v-col>
                     </template>
                   </Column>
           <Column v-for="(value, key) in providersTotals[0]" v-if="key !== 'name'" 
                   :key="key" :field="key"
                   :header="$t(key)"
                   headerClass="justify-end text-uppercase bg-c-white" 
                   bodyClass="justify-end bg-c-white" 
                   :styles="{ 'min-width': '10rem' }"
           >
             <template #body="{ data }">
               <div class="d-flex align-center font-weight-bold" style="white-space: nowrap;">
                 {{ data[key] }}
               </div>
             </template>
           </Column>
                       </DataTable>
                  </template>
                  
                  
                </Card>
                <Card class="card-custom" v-if='reports.items.length'>
                <template #title>
                  <h4 class="text-uppercase text-center">
                    {{ $t('games') }}
                  </h4>
                  </template>
                  <template #content>
                    <DataTable :value="reports.items" :rows="reports.items.length" :loading="loadingFlag"
                    :scrollable="true" responsiveLayout="scroll" class="p-datatable-sm">
           <Column field="game" 
                   :showFilterMenu="false" 
                   :header="$t('game')"
                   bodyClass="bg-c-white"
                   headerClass="bg-c-white text-uppercase"
                   :styles="{ 'min-width': '12rem' }"
           >
             <template #body="{ data }">
               <v-col class="pa-0 d-flex flex-wrap"><span class="font-weight-bold">{{ data.game }}</span></v-col>
             </template>
           </Column>
           <Column v-for="(key, index) in Object.keys(reports.items[0]).filter(k => k !== 'game')" 
                   :key="index" :field="key"
                   :header="$t(key)"
                   headerClass="justify-end text-uppercase bg-c-white" 
                   bodyClass="justify-end bg-c-white" 
                   :styles="{ 'min-width': '10rem' }"
           >
             <template #body="{ data }">
               <div class="d-flex align-center font-weight-bold" style="white-space: nowrap;">
                 {{ data[key] }}
               </div>
             </template>
           </Column>
         </DataTable> 
                  </template>
                  
                  
                </Card>
                
              </div>
              </div>

         
        </div>
      </template>
    </Card>
  </div>
  </v-main>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapGetters } from 'vuex';
  export default {
    components:{
      DateRangePicker
    },
      name: 'GamesReport',
      data() {
          return {
              currentLocale: this.$i18n.locale || 'en',
              today: new Date(),
              loadingFlag: true,
              showReport: false,
              loadingAgentsFlag: false,
              startAgentsLoadingFlag: false,
              activeFilters:{},
              datesToFilter: [],
              generatedCurrency: '',
              currentGroupBy: 'D',
              dateRange:{},
              filters:{
                currency: 'USD',
                show_blank_data: false,
                // group_by: 'D',
                agent_id: '',
                result: 'json',
              },
              results: [
              {
                value: 'json'
              },
              {
                value: 'pdf'
              },
              ],
              locale_data: {
      direction: 'ltr',
      format: 'mm.dd.yyyy',
      separator: ' - ',
      applyLabel: this.$i18n.t('applyLabel'),
      cancelLabel: this.$i18n.t('cancelLabel'),
      weekLabel: this.$i18n.t('weekLabel'),
      customRangeLabel: this.$i18n.t('customRangeLabel'),
      daysOfWeek: [
        this.$i18n.t('sun'), 
      this.$i18n.t('mon'),
       this.$i18n.t('tue'), 
       this.$i18n.t('wed'), 
       this.$i18n.t('thu'), 
       this.$i18n.t('fri'), 
       this.$i18n.t('sat')],
      monthNames: [this.$i18n.t('jan'), this.$i18n.t('feb'), this.$i18n.t('mar'), this.$i18n.t('apr'), this.$i18n.t('may'), this.$i18n.t('jun'), this.$i18n.t('jul'), this.$i18n.t('aug'), this.$i18n.t('sep'), this.$i18n.t('oct'), this.$i18n.t('nov'), this.$i18n.t('dec')],
      firstDay: 0,
    },
              timePeriods:[
                {
                text: this.$t('day'), value: 'D'
              },
                {
                text: this.$t('week'), value: 'W'
              },
                {
                text: this.$t('month'), value: 'M'
              },
                {
                text: this.$t('year'), value: 'Y'
              }
            ],
          }
      },
      filters: {
  date(val) {
   return val ? moment(val).format("YYYY-MM-DD") : "";
 }
},
      computed:{
          ...mapGetters({
              reports: 'reports/getGamesReport',
              providersTotals: 'reports/getGamesReportProvidersTotals',
              aggregatorsTotals: 'reports/getGamesReportAggregatorsTotals',
              overallTotals: 'reports/getGamesReportOverallTotals',
              currencies: 'getFilteredCurrencies',
              tokenUser: 'tokenUser',
              admin: 'admin',
              agents: 'agents/getAgents',
          }),
          isAgent(){
                return this.tokenUser === "agent";
              },
              getCurrencyValue(){
          if (this.isAgent) {
            return this.admin.currency;
          }  else{
            if (!this.getAgentID) {
              return 'USD';
            }
            const parentAgent = this.agents.items.find(agent => agent.id === this.getAgentID);
            if (parentAgent) {
              return parentAgent.currency;
            } else{ 
              return 'USD';
            }
          }
          },
          getAgentID(){
            if (!this.agents.items && this.agents.items.length === 0) {
              return null;
            }
              if (this.isAgent) {
                return this.admin.id;
            } else {
              const parentAgent = this.agents.items.find(agent => agent.id === this.filters.agent_id);
              // console.log(parentAgent);
              if (parentAgent) {
                return parentAgent.id;
              } else{
                      return null;
              }
            }
          },
      },
      beforeRouteEnter(to, from, next){
  next(vm => {
    vm.$store.commit('setPageName', 'games_report');
  });
  },
      async mounted(){
        this.dateRange = { startDate: moment().subtract(6, 'days'), endDate: moment() }
        if (this.isAgent) {
            this.filters.agent_id = this.admin.id;
          }
        this.loadingFlag = false;
      },
      methods:{
        toClearFilterAgent(){
          this.filters.agent_id = '';
          //this.
        },
        async startAgentsLoading(){
          if (this.agents.items.length) {
            return
          }
          this.startAgentsLoadingFlag = true;
          const filters = {
            size: 100,
            page: 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.startAgentsLoadingFlag = false;
          }
        },
        async loadAgentsNextPage(){
          this.loadingAgentsFlag = true;
          const filters = {
            size: 100,
            page: this.agents.page + 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.loadingAgentsFlag = false;
          }
        },
        getFormattedDates(date){
          return moment(date).format('YYYY-MM-DD')
        },
        getFormattedPeriod(period){
          switch (period) {
            case 'D':
            return this.$t('day');
            case 'W':
                return this.$t('week');
                case 'M': 
                return this.$t('month');
                case 'Y':
                return this.$t('year');
          }
        },
        customClass(field){
           return [
                 {
                    //  'total': field.name.includes('total_'),
                     'title': field.values.length === 0,
                 }
             ];
        },
        getValueForDate(data, date) {
          const values = data.values;
          //console.log(date);
          //console.log(values);
          if (values.length === 0) {
            return '';
          }
          const matchingItem = values.find(item => item.created_at === date);
          return matchingItem && matchingItem.value ? matchingItem.value : 0;
          //if (data.name.includes('count')) {
          //  // console.log(data);
          //  return matchingItem && matchingItem.value ? `${matchingItem.value}` : `0`;
          //} else if(data.name.includes('percent')){
          //  return matchingItem && matchingItem.value ? `${matchingItem.value}%` : `0%`;
          //} else{
          //
          //   return matchingItem && matchingItem.value ? `${matchingItem.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.generatedCurrency}` : `0`;
          //}
  },
          async generateReport(){
              this.loadingFlag = true;
              if (this.isAgent || this.filters.agent_id) {
                this.filters.currency = this.getCurrencyValue;
              }
              this.filters.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
              this.filters.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
              this.filters.tz_offset = moment(this.dateRange.startDate).format('Z')
              // console.log('this.filters.tz_offset', this.filters.tz_offset);
              //var originalDate = moment(this.dateRange.startDate, "ddd MMM DD YYYY HH:mm:ss GMTZ");
              // this.filters.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
              // this.filters.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let agent = false;
            if (this.isAgent || this.filters.agent_id) {
              agent = true;
            }
              try {
                await this.$store.dispatch('reports/getGamesReport', {filters: this.filters, agent: agent});
                this.generatedCurrency = this.filters.currency;
                if (this.filters.result !== 'pdf') {
                  this.showReport = true;
                }
                this.$toast.add({severity: 'success', summary: this.$t('success_report'), life: 3000}) 
               } catch (error) {
                 this.$toast.add({severity: 'error', summary: this.$t('just_error'), life: 3000}) 
               } finally{

                 this.loadingFlag = false;
               }
          },
          deleteFilters(){
              this.loadingFlag = true;
              this.showReport = false;
              if (!this.isAgent) {
                this.filters.agent_id = '';
              }
              this.datesToFilter = [];
              this.$nextTick(() =>{
                this.loadingFlag = false;
              })
          },
      },
  }
</script>

<style lang="scss" scoped>
.report{
  overflow: auto;
}

.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    //top: 0;
    //left: 0;
    //position: absolute;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.filtersCard {
  width: 100%;
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
        position: static !important;
    }
}
</style>